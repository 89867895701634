import * as React from "react"
import { graphql } from 'gatsby'
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Gallery from '@browniebroke/gatsby-image-gallery'

import Layout from "../components/layout"
import Seo from "../components/seo"
import * as styles from "../components/index.module.css"

const links = []
// const links = [
//   {
//     text: "Tutorial",
//     url: "https://www.gatsbyjs.com/docs/tutorial",
//     description:
//       "A great place to get started if you're new to web development. Designed to guide you through setting up your first Gatsby site.",
//   },
//   {
//     text: "Examples",
//     url: "https://github.com/gatsbyjs/gatsby/tree/master/examples",
//     description:
//       "A collection of websites ranging from very basic to complex/complete that illustrate how to accomplish specific tasks within your Gatsby sites.",
//   },
//   {
//     text: "Plugin Library",
//     url: "https://www.gatsbyjs.com/plugins",
//     description:
//       "Learn how to add functionality and customize your Gatsby site or app with thousands of plugins built by our amazing developer community.",
//   },
//   {
//     text: "Build and Host",
//     url: "https://www.gatsbyjs.com/cloud",
//     description:
//       "Now you’re ready to show the world! Give your Gatsby site superpowers: Build and host on Gatsby Cloud. Get started for free!",
//   },
// ]

// const samplePageLinks = [
//   {
//     text: "Page 2",
//     url: "page-2",
//     badge: false,
//     description:
//       "A simple example of linking to another page within a Gatsby site",
//   },
//   { text: "TypeScript", url: "using-typescript" },
//   { text: "Server Side Rendering", url: "using-ssr" },
//   { text: "Deferred Static Generation", url: "using-dsg" },
// ]

const samplePageLinks = []

const moreLinks = [
  {
    text: "Gallery", url: "gallery", isInternal:true
  },
  {
    text: 'wechat: wwavelet',
  },
  {
    text: 'weibo',
    url: 'https://weibo.com/u/1829658874'
  }
]

// const moreLinks = [
//   { text: "Join us on Discord", url: "https://gatsby.dev/discord" },
//   {
//     text: "Documentation",
//     url: "https://gatsbyjs.com/docs/",
//   },
//   {
//     text: "Starters",
//     url: "https://gatsbyjs.com/starters/",
//   },
//   {
//     text: "Showcase",
//     url: "https://gatsbyjs.com/showcase/",
//   },
//   {
//     text: "Contributing",
//     url: "https://www.gatsbyjs.com/contributing/",
//   },
//   { text: "Issues", url: "https://github.com/gatsbyjs/gatsby/issues" },
// ]

const utmParameters = ''
// const utmParameters = `?utm_source=starter&utm_medium=start-page&utm_campaign=default-starter`

const IndexPage = (props) => {


  return <Layout>
    <Seo />
    <div className={styles.textCenter}>
      <StaticImage
        src="../images/avatar01.png"
        loading="eager"
        width={128}
        height={128}
        quality={95}
        formats={["auto", "webp", "avif"]}
        alt=""
        style={{ marginBottom: `var(--space-3)`, borderRadius: '64px' }}
      />
      <h1>
        Hello
      </h1>
      <p className={styles.intro}>
        {/* <b>Example pages:</b>{" "} */}
        {samplePageLinks.map((link, i) => (
          <React.Fragment key={link.url}>
            <Link to={link.url}>{link.text}</Link>
            {i !== samplePageLinks.length - 1 && <> · </>}
          </React.Fragment>
        ))}
        <br />
        {/* Edit <code>src/pages/index.js</code> to update this page. */}
      </p>
    </div>
    <ul className={styles.list}>
      {links.map((link,i) => (
        <li key={i} className={styles.listItem}>
          <a
            className={styles.listItemLink}
            href={`${link.url}${utmParameters}`}
          >
            {link.text} ↗
          </a>
          <p className={styles.listItemDescription}>{link.description}</p>
        </li>
      ))}
    </ul>
    <div style={{
      textAlign: 'center'
    }}>
    {moreLinks.map((link, i) => (
      <React.Fragment key={i}>
        {
          link.isInternal? <Link to={link.url}>{link.text}</Link>
         : link.url? <a href={`${link.url}${utmParameters}`}>{link.text}</a> : <a >{link.text}</a>
        }
        {i !== moreLinks.length - 1 && <> · </>}
      </React.Fragment>
    ))}
    </div>
  </Layout>
}

/**
 * Head export to define metadata for the page
 *
 * See: https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-head/
 */
// export const Head = () => <Seo title="Home" />



export default IndexPage
